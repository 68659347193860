var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NEW_URL } from "../../api/API";
import { useAppDispatch } from "../../redux/store";
import style_layout from "../../styles/layout/grid.module.css";
import style from "./StartupByIdPage.module.css";
import { useSelector } from "react-redux";
import { UserDeskForProfilePageView } from "../../components/Profiles/userDeskForProfilePageView/userDeskForProfilePageView";
import { useMediaQuery } from "react-responsive";
import LoadableUserCover from "../../components/LoadableImage/LoadableUserCover/LoadableUserCover";
import { useQuery, useQueryClient } from "react-query";
import { NoPostYetCardForProfileView } from "../../components/NoPostYetCardForProfileView/NoPostYetCardForProfileView";
import { StartupByIdPageRightset } from "./StartupByIdPageRightSet/StartupByIdPageRightSet";
import { StartupByIdChange } from "./StartupByIdChangeBlock";
import { fetchInformationAboutStartupById, setToggleStartupByIdBlockButtons } from "@src/redux/reducers/startupByIdReducer/startupByIdReducer";
var StartupByIdPage = function () {
    var _a, _b;
    var isMobile = useMediaQuery({
        query: "(max-width: 980px)",
    });
    var _c = useParams(), id = _c.id, content = _c.content;
    var dispatch = useAppDispatch();
    var queryClient = useQueryClient();
    var navigate = useNavigate();
    var bottomBoundaryRef = useRef(null);
    var buttonForStartupById = useSelector(function (state) { return state.startupById.buttonForStartupById; });
    var _d = useQuery(["fetchInfoAboutStartup", id], function () { return fetchInformationAboutStartupById(id); }), startupByIdInformation = _d.data, isFetchedAfterMount = _d.isFetchedAfterMount;
    //фокус перемещается вверх страницы
    useEffect(function () {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    useEffect(function () {
        switch (content) {
            case "publications":
                dispatch(setToggleStartupByIdBlockButtons(1));
                break;
            case "events":
                dispatch(setToggleStartupByIdBlockButtons(2));
                break;
            case "vacancies":
                dispatch(setToggleStartupByIdBlockButtons(3));
                break;
            case "about":
                dispatch(setToggleStartupByIdBlockButtons(4));
                break;
            case "steps":
                dispatch(setToggleStartupByIdBlockButtons(5));
        }
    }, []);
    useEffect(function () {
        return function () {
            queryClient.removeQueries("fetchUserFeedOnStartupView");
            queryClient.removeQueries("fetchRightSetInStartupView");
            queryClient.removeQueries("fetchInfoAboutStartup");
        };
    }, []);
    var handleProfileBlockButtons = function (buttonId) {
        var content = "";
        switch (buttonId) {
            case 1:
                content = "/publications";
                break;
            case 2:
                content = "/events";
                break;
            case 3:
                content = "/vacancies";
                break;
            case 4:
                content = "/about";
                break;
            case 5:
                content = "/steps";
                break;
            default:
                content = "";
        }
        navigate("/startupProfile/".concat(id).concat(content, "/"));
        dispatch(setToggleStartupByIdBlockButtons(buttonId));
    };
    return (_jsxs("div", __assign({ className: style_layout.col_main_content_section_vertical }, { children: [_jsx("div", __assign({ className: style.main_header }, { children: _jsx(LoadableUserCover, { isLoading: !isFetchedAfterMount, src: "".concat(NEW_URL).concat(startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.cover) }) })), _jsx(UserDeskForProfilePageView, { visibleMenuList: startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.visible_menu, isUserInfoLoading: !isFetchedAfterMount, userData: startupByIdInformation, userProfileBlockButtons: buttonForStartupById, toggleButtonsFunc: handleProfileBlockButtons, isStartup: true, budget: (_a = startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.about) === null || _a === void 0 ? void 0 : _a.budget }), _jsxs("div", __assign({ className: style.bottom_part }, { children: [_jsxs("div", __assign({ className: isMobile ? style_layout.col_main_content_section_vertical_mobile : style_layout.col_middle_large }, { children: [_jsx(StartupByIdChange, { bottomBoundaryRef: bottomBoundaryRef, startupByIdInformation: startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.about }), !((_b = startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.visible_menu) === null || _b === void 0 ? void 0 : _b.length) && _jsx(NoPostYetCardForProfileView, { text: "У автора пока нет контента в профиле." }), _jsx("div", __assign({ ref: bottomBoundaryRef }, { children: "&nbsp" }))] })), !isMobile && isFetchedAfterMount && (_jsx("div", __assign({ className: style.stickyWrapper }, { children: _jsx("div", __assign({ className: style_layout.col_rightview_small }, { children: _jsx(StartupByIdPageRightset, { startupByIdInformation: startupByIdInformation }) })) })))] }))] })));
};
export default StartupByIdPage;
