// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1240px) {
    .sNRqjO3SJ6UT5oTnU10g {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 18px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Startups/Startups.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,8BAA8B;QAC9B,mBAAmB;QACnB,kBAAkB;IACtB;AACJ","sourcesContent":["@media screen and (max-width: 1240px) {\n    .page_head {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        margin-right: 18px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page_head": `sNRqjO3SJ6UT5oTnU10g`
};
export default ___CSS_LOADER_EXPORT___;
